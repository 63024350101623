body {
  font-family: "Nunito", sans-serif;
  padding-top: 30px;
  box-sizing: border-box;
}

h1 {
  display: block;
  font-weight: 300;
  font-size: 48px;
  margin-bottom: 0.55em;
  margin-left: -5px;
}

.appname {
  font-weight: 400;
  font-size: 27px;
  margin-bottom: 0.25em;
}

.mobile-screenshot {
  display: none;
}

.caption {
  margin-bottom: 2em;
  font-size: 14px;
  line-height: 16px;
  color: #616161;
  font-weight: 400;
}

.intro-box {
  text-align: left;
}

.intro-link {
  font-weight: 500;
}

.intro {
  font-size: 16px;
  font-weight: 400;
}

.project-description {
  font-size: 16px;
}

.project-row {
  margin-bottom: 4em;
}

.profile {
  height: 177px;
  float: right;
  border-radius: 50%;
  margin: 1em;
}

.actions {
  margin-top: 2.9em;
}

.actions a {
  color: #81caff;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  margin-right: 1.1em;
  padding: 6px 8px;
  border: 2px solid #81caff;
  border-radius: 3px;
  transition: 0.2s all ease;
}

.private-repo {
  color: #bebebe;
  font-size: 16px;
  line-height: 17px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  margin-right: 0.9em;
  padding: 6px 8px;
  border: 2px solid #bebebe;
  border-radius: 3px;
  cursor: default;
}

.actions > a:hover {
  color: rgb(22, 22, 22);
  background-color: #81caff;
  transform: scale(1.08);
}

hr {
  border-color: #81caff;
}

.project {
  text-align: right;
}

/* .music-note {
  padding-bottom: 4em;
  font-size: 15px;
} */

.screenshot {
  width: 93%;
  transition: 0.3s transform ease;
}

.screenshot:hover {
  transform: scale(1.03);
}

@media (max-width: 991px) {
  .project {
    text-align: left;
  }

  .screenshot {
    width: 97%;
  }

  .profile {
    height: 145px;
    margin-top: 0px;
  }
}

@media (max-width: 800px) {
  .profile {
    margin: 0.75em;
  }
}

@media (max-width: 580px) {
  .profile {
    height: 120px;
  }
}

@media (max-width: 992px) {
  .desktop-screenshot {
    display: none;
  }

  .mobile-screenshot {
    display: inline;
  }

  .row {
    margin-bottom: 2.5em;
  }
}

@media (max-width: 370px) {
  .profile {
    height: 110px;
  }

  .row {
    margin-bottom: 1.5em;
  }
}

/* @media (prefers-color-scheme: dark) { */
body,
html {
  background: #15141c;
  color: white;
}

a,
a:hover,
a:visited,
a:active {
  color: #81caff;
}

.caption {
  color: #c2c2c2;
}

.private-repo {
  color: #838383;
  border: 2px solid #838383;
}
/* } */
